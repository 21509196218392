import axios from "axios";

class Api {
  getAcquisitions = async (
    {
      name,
      requester_email,
      ticket,
      description,
      segmentDescription,
      qualityLabels,
      utilityLabels,
      infoFields,
      page,
      size,
    },
    token
  ) => {
    const { data } = await axios.get(
      `/api/dashboard/?
        name=${encodeURIComponent(name)}&
        requester_email=${encodeURIComponent(requester_email)}&
        ticket=${encodeURIComponent(ticket)}&
        description=${encodeURIComponent(description)}&
        segment_description=${encodeURIComponent(segmentDescription)}&
        ${qualityLabels
          .map(
            (qualityLabel) =>
              `quality_labels=${encodeURIComponent(qualityLabel)}&`
          )
          .join("")}
        ${utilityLabels
          .map(
            (utilityLabel) =>
              `utility_labels=${encodeURIComponent(utilityLabel)}&`
          )
          .join("")}
        ${infoFields
          .map(
            (infoField) =>
              `info_fields=${encodeURIComponent(JSON.stringify(infoField))}&`
          )
          .join("")}
        page=${page}&
        size=${size}
        `.replace(/\s/g, ""),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  };

  exportData = async (
    {
      name,
      requester_email,
      ticket,
      description,
      segmentDescription,
      qualityLabels,
      utilityLabels,
      infoFields,
      page,
      size,
    },
    token
  ) => {
      console.log("Export request initiated");
      const response = await axios.get(
        `/api/dashboard/export?
          name=${encodeURIComponent(name)}&
          requester_email=${encodeURIComponent(requester_email)}&
          ticket=${encodeURIComponent(ticket)}&
          description=${encodeURIComponent(description)}&
          segment_description=${encodeURIComponent(segmentDescription)}&
          ${qualityLabels
            .map(
              (qualityLabel) =>
                `quality_labels=${encodeURIComponent(qualityLabel)}&`
            )
            .join("")}
          ${utilityLabels
            .map(
              (utilityLabel) =>
                `utility_labels=${encodeURIComponent(utilityLabel)}&`
            )
            .join("")}
          ${infoFields
            .map(
              (infoField) =>
                `info_fields=${encodeURIComponent(JSON.stringify(infoField))}&`
            )
            .join("")}
          page=${page}&
          size=${size}
          `.replace(/\s/g, ""),
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]), {type: 'text/plain'});
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0,10);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `datahub_export_${formattedDate}.txt`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link)
      link.remove();
    };

  getQualities = async ({ label, used }, token) => {
    const { data } = await axios.get(
      `/api/qualities/?${label ? `label=${label}` : ""}${
        used ? `&used=${used}` : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  };

  postQuality = async (label, token) => {
    const { data } = await axios.post(
      "/api/qualities/",
      { label },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  };

  getUtilities = async ({ label, used }, token) => {
    const { data } = await axios.get(
      `/api/utilities/?${label ? `label=${label}` : ""}${
        used ? `&used=${used}` : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  };

  postUtility = async (label, token) => {
    const { data } = await axios.post(
      "/api/utilities/",
      { label },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  };

  patchAcquisition = async (id, acquisitionFields, token) => {
    const { data } = await axios.patch(
      `/api/acquisitions/${id}`,
      acquisitionFields,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  };

  patchSegment = async (id, segmentFields, token) => {
    const { data } = await axios.patch(`/api/segments/${id}`, segmentFields, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  };

  patchJob = async (id, jobFields, token) => {
    const { data } = await axios.patch(`/api/jobs/${id}`, jobFields, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  };

  getTags = async (label, token) => {
    const { data } = await axios.get(`/api/tags/?label=${label}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  };

  postTag = async (label, token) => {
    const { data } = await axios.post(
      "/api/tags/",
      { label },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  };

  getSensorInfoFields = async (token) => {
    const { data } = await axios.get("/api/sensors/info-fields", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  };

  getCurrentUser = async (token) => {
    const { data } = await axios.get("/api/users/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  };

  signInGoogleUser = async (token) => {
    const { data } = await axios.post("/api/auth/google", token);
    return data;
  };
}

export default new Api();
