import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { handleIfUnauthorized } from "../common/errorHandlers";

import Api from "../api";

const cols = [
  "Ticket",
  "Team",
  "Requester Email",
  "Tags",
  "Description",
  "Radars",
  "Cameras",
  "Lidars",
  "Segment Name",
  "Segment Path",
  "Segment Description",
  "Segment Location",
  "Segment Duration",
  "Segment Distance",
  "Segment Quality Labels",
  "Job Type",
  "Job Date",
  "Job Git Sha",
  "Job Output Path",
  "Job Utility Labels",
];

export const initialState = {
  columns: cols,
  selectedColumns: [],
  acquisitions: {
    loading: false,
    data: [],
    metadata: {
      page: 1,
      size: 0,
      total: 0,
      totalSegments: 0
    },
    error: null,
  },
  qualities: {
    loading: false,
    data: [],
    error: null,
  },
  utilities: {
    loading: false,
    data: [],
    error: null,
  },
  sensorInfoFields: {
    loading: false,
    data: [],
    error: null,
  },
};

export const getQualities = createAsyncThunk(
  "table/getQualities",
  async ({ label = "", used = "" }, { rejectWithValue, getState }) => {
    const {
      auth: { token },
    } = getState();
    try {
      const labels = await Api.getQualities({ label, used }, token);
      return labels;
    } catch (err) {
      handleIfUnauthorized(err);
      return rejectWithValue(
        err.response ? err.response.data.detail : err.message
      );
    }
  }
);

export const getUtilities = createAsyncThunk(
  "table/getUtilities",
  async ({ label = "", used = "" }, { rejectWithValue, getState }) => {
    const {
      auth: { token },
    } = getState();
    try {
      const labels = await Api.getUtilities({ label, used }, token);
      return labels;
    } catch (err) {
      handleIfUnauthorized(err);
      return rejectWithValue(
        err.response ? err.response.data.detail : err.message
      );
    }
  }
);

export const getSensorInfoFields = createAsyncThunk(
  "table/getSensorInfoFields",
  async (_, { rejectWithValue, getState }) => {
    const {
      auth: { token },
    } = getState();
    try {
      const labels = await Api.getSensorInfoFields(token);
      return labels;
    } catch (err) {
      handleIfUnauthorized(err);
      return rejectWithValue(
        err.response ? err.response.data.detail : err.message
      );
    }
  }
);

export const getAcquisitions = createAsyncThunk(
  "table/getAcquisitions",
  async (
    {
      name = "",
      requester_email = "",
      ticket = "",
      description = "",
      segmentDescription = "",
      qualityLabels = "",
      utilityLabels = "",
      infoFields = "",
      page = 1,
      size = 10,
    },
    { rejectWithValue, getState }
  ) => {
    const {
      auth: { token },
    } = getState();
    try {
      const acquisitions = await Api.getAcquisitions(
        {
          name,
          requester_email,
          ticket,
          description,
          segmentDescription,
          qualityLabels,
          utilityLabels,
          infoFields,
          page,
          size,
        },
        token
      );
      return acquisitions;
    } catch (err) {
      handleIfUnauthorized(err);
      return rejectWithValue(
        err.response ? err.response.data.detail : err.message
      );
    }
  }
);

export const exportData = createAsyncThunk(
  "table/exportData",
  async (
    {
      name = "",
      requester_email = "",
      ticket = "",
      description = "",
      segmentDescription = "",
      qualityLabels = "",
      utilityLabels = "",
      infoFields = "",
      page = 1,
      size = 10,
    },
    { rejectWithValue, getState }
  ) => {
    const {
      auth: { token },
    } = getState();
    try {
      const response = await Api.exportData(
        {
          name,
          requester_email,
          ticket,
          description,
          segmentDescription,
          qualityLabels,
          utilityLabels,
          infoFields,
          page,
          size,
        },
        token
      );
      return response;
    } catch (err) {
      handleIfUnauthorized(err);
      return rejectWithValue(
        err.response ? err.response.data.detail : err.message
      );
    }
  }
);

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    updateSelectedColumns: (state, action) => {
      state.selectedColumns = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAcquisitions.pending, (state) => {
        state.acquisitions.loading = true;
        state.acquisitions.data = [];
        state.acquisitions.metadata = initialState.acquisitions.metadata;
        state.acquisitions.error = null;
      })
      .addCase(getAcquisitions.rejected, (state, action) => {
        state.acquisitions.loading = false;
        state.acquisitions.data = [];
        state.acquisitions.metadata = initialState.acquisitions.metadata;
        state.acquisitions.error = action.payload
          ? action.payload
          : "Error: Failed to fetch data.";
      })
      .addCase(exportData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(exportData.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(exportData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Export Failed";
      })
      .addCase(getAcquisitions.fulfilled, (state, action) => {
        state.acquisitions.loading = false;
        state.acquisitions.data = action.payload["data"];
        state.acquisitions.metadata = action.payload["metadata"];
        state.acquisitions.error = null;
      })
      .addCase(getQualities.pending, (state) => {
        state.qualities.loading = true;
        state.qualities.data = [];
        state.qualities.error = null;
      })
      .addCase(getQualities.rejected, (state, action) => {
        state.qualities.loading = false;
        state.qualities.data = [];
        state.qualities.error = action.payload
          ? action.payload
          : "Error: Failed to fetch data.";
      })
      .addCase(getQualities.fulfilled, (state, action) => {
        state.qualities.loading = false;
        state.qualities.data = action.payload;
        state.qualities.error = null;
      })
      .addCase(getUtilities.pending, (state) => {
        state.utilities.loading = true;
        state.utilities.data = [];
        state.utilities.error = null;
      })
      .addCase(getUtilities.rejected, (state, action) => {
        state.utilities.loading = false;
        state.utilities.data = [];
        state.utilities.error = action.payload
          ? action.payload
          : "Error: Failed to fetch data.";
      })
      .addCase(getUtilities.fulfilled, (state, action) => {
        state.utilities.loading = false;
        state.utilities.data = action.payload;
        state.utilities.error = null;
      })
      .addCase(getSensorInfoFields.pending, (state) => {
        state.sensorInfoFields.loading = true;
        state.sensorInfoFields.data = [];
        state.sensorInfoFields.error = null;
      })
      .addCase(getSensorInfoFields.rejected, (state, action) => {
        state.sensorInfoFields.loading = false;
        state.sensorInfoFields.data = [];
        state.sensorInfoFields.error = action.payload
          ? action.payload
          : "Error: Failed to fetch data.";
      })
      .addCase(getSensorInfoFields.fulfilled, (state, action) => {
        state.sensorInfoFields.loading = false;
        state.sensorInfoFields.data = action.payload;
        state.sensorInfoFields.error = null;
      });
  },
});

export const { updateSelectedColumns, updateFilters } = tableSlice.actions;

export const selectedColumns = (state) => state.table.selectedColumns;
export const tableState = (state) => state.table;
export const pageMetadataState = (state) => state.table.acquisitions.metadata;

export default tableSlice.reducer;
